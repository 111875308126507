import { apiHelper } from "../utils/helpers";

export default {
  earnedPoints: {
    get({ page, pagesize, phonenumber, cardno, startdate, enddate }) {
      let search = { page, pagesize, phonenumber, cardno };
      if (startdate) {
        search = {
          startdate,
          ...search,
        };
      }
      if (enddate) {
        search = {
          enddate,
          ...search,
        };
      }
      const searchParams = new URLSearchParams(search);
      return apiHelper.get(`/points/earned?${searchParams.toString()}`);
    },
  },
  pointsTransactions: {
    get({ page, pagesize, phonenumber, cardno, startdate, enddate }) {
      let search = { page, pagesize, phonenumber, cardno };
      if (startdate) {
        search = {
          startdate,
          ...search,
        };
      }
      if (enddate) {
        search = {
          enddate,
          ...search,
        };
      }
      const searchParams = new URLSearchParams(search);
      return apiHelper.get(`/points/used?${searchParams.toString()}`);
    },
  },
};
