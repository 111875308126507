<template>
  <main id="charge">
    <section class="section-top point">
      <h1 class="section-top-title mb-3">充電卡 U-POINT 點數查詢</h1>
      <!-- <p class="section-top-text">
        請輸入您的手機號碼及充電卡卡號 <br />或使用 U-POWER App
        登入會員可查看餘額
      </p> -->
    </section>
    <section class="section-main py-1 my-2">
      <form @submit.prevent="handleSubmit">
        <ul class="form" :class="{ 'form-shake': isNotFound }">
          <li class="phone form-item">
            <label for="phone">手機號碼</label>
            <input
              type="tel"
              name="phone"
              id="phone"
              class="input is-static-line"
              inputmode="tel"
              required
              maxlength="10"
              placeholder="請輸入您的手機號碼"
              v-model="phone"
              ref="phoneInput"
              autofocus
              :class="[phoneDefault ? '' : phoneValid ? 'valid' : 'invalid']"
            />
            <div v-if="!phoneValid && !phoneDefault" class="error">
              <small>請輸入有效的手機號碼格式09XXXXXXXX</small>
            </div>
            <div class="desc">
              <small>
                請輸入您交車時提供給南陽實業的手機號碼，您所登記的手機號碼由車廠提供，U-POWER不會做任何相關變更。若有任何疑問請聯絡：<br />南陽實業
                HYUNDAI 客服中心 :
                <a href="tel: 0800-00-33-55">&nbsp;0800-00-33-55</a>。
              </small>
            </div>
          </li>
          <li class="form-item">
            <label for="card-no">充電卡卡號</label>
            <input
              type="tel"
              name="card-no"
              id="card-no"
              class="input is-static-line"
              required
              pattern="[0-9]*"
              inputmode="numeric"
              maxlength="10"
              placeholder="請輸入您的充電卡卡號"
              v-model="cardNo"
              ref="cardInput"
              :class="[cardDefault ? '' : cardValid ? 'valid' : 'invalid']"
            />
            <div v-if="!cardValid && !cardDefault" class="error">
              <small>請輸入有效的充電卡卡號</small>
            </div>
          </li>
          <div class="submit form-item_action">
            <button
              type="submit"
              class="button is-rounded is-fullwidth is-link"
              :disabled="
                isProcessing ||
                !phoneValid ||
                !cardValid ||
                cardDefault ||
                phoneDefault
              "
            >
              {{ isProcessing ? "送出中..." : "確認送出" }}
            </button>
          </div>
        </ul>
      </form>
      <div class="message-box" v-if="isNotFound === true">
        <p>查無相關資訊，請重新查詢。</p>
      </div>
    </section>
  </main>
</template>
<script>
import pointsAPI from "../apis/points.js";
import dayjs from "dayjs";
export default {
  name: "PointQuery.vue",
  data() {
    return {
      isProcessing: false,
      phone: "",
      cardNo: "",
      check: false,
      phoneValid: false,
      phoneDefault: true,
      cardValid: false,
      cardDefault: true,
      isNotFound: false,
    };
  },
  methods: {
    validPhone(phone) {
      let re = /^09[0-9]{8}$/g;
      return re.test(phone);
    },
    validCardNo(no) {
      let re = /^[0-9]{10}$/g;
      return re.test(no);
    },
    async handleSubmit() {
      try {
        this.isProcessing = true;
        const { data, status } = await pointsAPI.earnedPoints.get({
          page: 1,
          pagesize: 500,
          phonenumber: this.phone,
          cardno: this.cardNo,
        });
        if (!(status >= 200 && status < 300)) {
          throw new Error(status);
        }
        const { pointsEarneds } = data;

        if (pointsEarneds.length > 0) {
          const startDate = dayjs().subtract(30, "Day").format("YYYY-MM-DD");
          this.$router.push({
            name: "PointTransactions",
            query: {
              cardNo: this.cardNo,
              phoneNumber: this.phone,
              page: 1,
              pageSize: 500,
              startDate: startDate,
            },
          });
        } else {
          this.isNotFound = true;
          setTimeout(() => {
            this.isNotFound = false;
          }, 10 * 1000);
        }
        this.isProcessing = false;
      } catch (error) {
        this.isProcessing = false;
        this.isNotFound = true;
        setTimeout(() => {
          this.isNotFound = false;
        }, 10 * 1000);

        console.log(error);
      }
    },
  },
  watch: {
    phone: function (val) {
      if (val.length === 10 && this.validPhone(val)) {
        this.phoneValid = true;
        this.phoneDefault = false;
        this.$refs.cardInput.focus();
      } else if (val.length === 10) {
        this.phoneDefault = false;
        this.phoneValid = false;
      } else if (val.length === 0) {
        this.phoneDefault = true;
      }
    },
    cardNo: function (val) {
      if (val.length === 10 && this.validCardNo(val)) {
        this.cardValid = true;
        this.cardDefault = false;
      } else if (val.length === 10) {
        this.cardDefault = false;
        this.cardValid = false;
      } else if (val.length === 0) {
        this.cardDefault = true;
      }
    },
  },
};
</script>
